<template>
  <div id="mian">
		<div class="header" v-if="pageType == 'edit'">
			<span @click="toTypeUrl('shoplist_xq')">店铺详情</span>
			<span @click="toTypeUrl('shoplist_xq_shop')">报备商户号信息</span>
		</div>
		<div class="header" v-else>
		  <span id="fzcolor">基本信息</span>
		  <span @click="toUrl('add_shoplist_user_pz')">配置信息</span>
		</div>
    <div class="desc">
      <h5 class="jsxx">基本信息</h5>
    </div>
    <div class="input_con">
      <ul class="inputBox">
        <li><span><i>*</i>店铺名称</span>
          <el-input class="inputs" v-model="formData.storeName" maxlength="128" placeholder="输入店铺名称" tabindex="1"></el-input>
        </li>
        <li><span><i>*</i>所属商户</span>
          <el-select class="inputs" v-model="formData.mchId" placeholder="选择所属商户" :disabled="pageType == 'edit'" tabindex="2">
            <el-option v-for="v in mchDrop" :key="v.mchId" :label="v.mchName" :value="v.mchId">
            </el-option>
          </el-select>
        </li>
      </ul>
    </div>
		<div class="bottom" v-if="pageType == 'edit'">
		  <el-button class="back" type="primary" @click="$router.go(-1)">返回</el-button>
		  <el-button @click="onSubmit" class="next" type="primary">保存</el-button>
		</div>
    <div class="bottom" v-else>
      <el-button @click="toUrl('add_shoplist_user_pz')" class="next" type="primary">下一步</el-button>
    </div>
  </div>
</template>
<script>
	import {
		mapState,
		mapMutations
	} from 'vuex'
	import {
		merchantsDrop
	} from '@/api/common.js'
	import {
		getManualDetail,
		setManual
	} from '@/api/user/shopList.js'
  export default {
    data() {
      return {
        formData: {
          storeName:'',//	true	店铺名称
          mchId:'',//	Number	true	商户ID
          showInMch:false,//	是否展示在商户平台，true为是，false为否
        },
				mchDrop: [],//商户下拉
				pageType:''
      };
    },
		computed:{
			...mapState({
				storesManualDetail: 'user_storesManualDetail'
			})
		},
		created() {
			this.getDrop()
			if (this.$route.query.pageType == 'edit') {
				this.pageType = 'edit'
				this.getDetail()
			} else {
				if(this.storesManualDetail){
					if(this.storesManualDetail.storeDetail){
						this.formData = this.storesManualDetail.storeDetail
					}
				}
			}
		},
    methods: {
			...mapMutations({
				setStoresManual: 'user_setStoresManual'
			}),
			//获取详情
			getDetail(){
				getManualDetail(this.$route.query.id).then(res=>{
					if(res){
						this.formData = {
						  storeName:res.resultData.storeDetail.storeName,
						  mchId:res.resultData.storeDetail.mchId,
						  showInMch:res.resultData.storeDetail.showInMch,
						}
					}
				})
			},
			// 获取下拉菜单
			getDrop(){
				merchantsDrop().then(res=>{
					if(res){
						this.mchDrop = res.resultData
					}
				})
			},
			// 表单规则
			roles() {
				let data = JSON.parse(JSON.stringify(this.formData))
				if (!data.storeName) {
					this.$message.error('店铺名称必填')
					return false
				}
				if (!data.mchId) {
					this.$message.error('所属商户必选')
					return false
				}
				return true
			},
			// 页面跳转
			toUrl(name) {
				if (!this.roles()) {
					return
				}
				this.$router.push({
					name: name
				})
			},
			//保存
			onSubmit(){
				if (!this.roles()) {
					return
				}
				setManual(this.$route.query.id, this.formData).then(res=>{
					if(res){
            this.$message.success('保存成功')
						this.$router.go(-1)
					}
				})
			},
			// 页面修改状态的跳转
			toTypeUrl(name) {
				this.$router.push({
					name: name,
					query: {
						pageType: 'edit',
						id: this.$route.query.id,
						InfoType: this.$route.query.InfoType,
					}
				})
			},
    },
		beforeDestroy() {
			let formData = JSON.parse(JSON.stringify(this.formData))
			let data = {
				storeDetail: formData,
				storeTrades: this.storesManualDetail?this.storesManualDetail.storeTrades:null
			}
			this.setStoresManual(data)
		}
  }
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/editInput.css";
	.back {
		width: 120px;
		line-height: 10px;
		height: 32px;
		padding: 0;
		background: rgba(255, 255, 255, 1);
		border-color: #DCE0E6;
		border: 1px solid rgba(220, 224, 230, 1);
		border-radius: 3px;
		color: #333333;
		font-size: 12px;
	}

  .next {
    width: 120px;
    height: 32px;
    background: rgba(72, 184, 182, 1);
    border-color: #5AAFAB;
    border-radius: 3px;
    font-size: 12px;
    padding: 0;
  }

  .bottom {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 18px;
  }

</style>
